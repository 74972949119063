<template>
  <div :class="{ 'd-flex align-start': screenSize >= 900 }">
    <div class="table">
      <div class="header px-3 elevation-3">
        <v-row no-gutters>
          <v-col cols="2">
            <v-row>
              <v-col class="d-flex align-center justify-center"
                ><span class="content-text">{{
                  !$language ? "Avatar" : "ছবি"
                }}</span></v-col
              >
              <v-col v-if="screenSize >= 900" cols="3"></v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex align-center"
            ><span class="content-text">{{
              !$language ? "Name" : "নাম"
            }}</span></v-col
          >
          <v-col class="d-flex align-center"
            ><span class="content-text">{{
              !$language ? "Number" : "মোবাইল নাম্বার"
            }}</span></v-col
          >
          <v-col :cols="screenSize < 900 ? 3 : 2">
            <v-row>
              <v-col v-if="screenSize >= 900" cols="2"></v-col>
              <v-col class="d-flex align-center justify-center"
                ><span class="content-text">{{
                  !$language ? "Absent" : "অনুপস্থিত"
                }}</span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="content px-4 pt-2">
        <no-content-component
          v-if="studentList.length == 0"
          :message="noContentMessage"
        ></no-content-component>
        <v-row
          v-for="(student, index) in studentList"
          :key="index"
          no-gutters
          class="content-item"
        >
          <v-col cols="2">
            <v-row>
              <v-col
                :cols="screenSize < 900 ? 9 : 8"
                class="d-flex align-center justify-center"
                ><v-avatar
                  :color="student.color"
                  class="white--text"
                  :size="avatarSize"
                >
                  <span class="content-text">{{ student.avatar }}</span>
                </v-avatar></v-col
              >
              <v-col v-if="screenSize >= 900" cols="3"></v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex align-center"
            ><span class="content-text text-truncate">{{
              student.name
            }}</span></v-col
          >
          <v-col class="d-flex align-center"
            ><span class="content-text text-truncate">{{
              student.number
            }}</span></v-col
          >
          <v-col :cols="screenSize < 900 ? 3 : 2">
            <v-row>
              <v-col v-if="screenSize >= 900" cols="2"></v-col>
              <v-col class="d-flex align-center justify-center ml-4"
                ><mk-check-box
                  :disabled="enableEditing && !student.invalid"
                  color="#fc6222"
                  :index="index"
                  :size-object="checkDim"
                  min-width="12"
                  :active="student.absent"
                  @click="toggleActive"
                ></mk-check-box
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <div class="control">
        <h2 v-if="screenSize >= 600" class="control-heading">
          <span class="day-text custom-font text-uppercase"
            >{{ !$language ? "Today" : "আজ" }},</span
          ><span class="date-text custom-font">{{
            !$language ? `${currentDate}` : `${currentDateBN}`
          }}</span>
          <span class="date-text custom-font">{{
            !$language ? `${currentWeekDay}` : `${currentWeekDayBN}`
          }}</span>
        </h2>
        <p
          v-if="screenSize >= 900"
          class="text-body-2 pt-6 custom-font grey--text"
        >
          {{ !$language ? "Class Type" : "ক্লাস টাইপ" }}
        </p>
        <v-row
          :no-gutters="screenSize < 600"
          :class="{ 'pb-2': screenSize < 600 }"
        >
          <v-col
            v-if="screenSize < 900"
            cols="3"
            class="d-flex justify-center align-center"
          >
            <p class="text-body-2 custom-font grey--text">
              <span class="content-text">{{
                !$language ? "Class Type" : "ক্লাস টাইপ"
              }}</span>
            </p>
          </v-col>
          <v-col>
            <v-btn
              outlined
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :small="screenSize < 600"
              block
              class="no-effect"
              ><span class="content-text">{{
                !$language ? `${batchType}` : `${batchTypeBN}`
              }}</span></v-btn
            >
          </v-col>
          <v-col :class="{ 'pl-2': screenSize < 600 }">
            <v-btn
              v-if="!enableEditing && allowEdit"
              class="white--text custom-font"
              color="#0099dc"
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :small="screenSize < 600"
              block
              @click="toggleEditing()"
              ><span class="content-text">{{
                !$language ? "Edit Attendance" : "উপস্থিতি এডিট করুন"
              }}</span></v-btn
            >
          </v-col>
        </v-row>
        <p
          v-if="screenSize >= 900"
          class="text-body-2 pt-2 pb-3 custom-font grey--text"
        >
          {{ !$language ? "Date" : "তারিখ" }}
        </p>
        <v-row no-gutters>
          <v-col :cols="screenSize < 900 ? '7' : '12'">
            <mk-date-slider
              v-if="drawSlider"
              :date-list="dateList"
              @slide="slidehandeler"
            ></mk-date-slider>
          </v-col>
          <v-col v-if="screenSize < 900" class="d-flex align-center">
            <v-row no-gutters>
              <v-col
                :class="[
                  'd-flex align-center',
                  screenSize < 600 ? 'justify-end' : 'justify-center',
                ]"
              >
                <p class="custom-font descriptor mk-blue">
                  {{ !$language ? "Present" : "উপস্থিত" }}:
                </p>
              </v-col>
              <v-col cols="4" class="d-flex align-center justify-end">
                <v-avatar
                  tile
                  color="#0099dc"
                  class="white--text rounded"
                  :size="msgDim.width"
                >
                  <span>{{ presentStudent }}</span>
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="screenSize >= 900">
          <v-row class="mt-6 py-0">
            <v-col class="d-flex align-center pb-0">
              <p class="custom-font descriptor">
                {{ !$language ? "Total Students:" : "মোট শিক্ষার্থী:" }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-row no-gutters>
                <v-col cols="5" class="pa-0">
                  <v-avatar
                    tile
                    color="black"
                    class="white--text rounded"
                    :size="msgDim.width"
                  >
                    <span>{{ totalStudent }}</span>
                  </v-avatar>
                </v-col>
                <v-col class="pa-0 d-flex justify-space-between">
                  <v-btn
                    color="white"
                    class="black--text"
                    :height="msgDim.height"
                    :width="msgDim.width"
                    min-width="20"
                  >
                    <v-icon>mdi-chat</v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    class="black--text"
                    :height="msgDim.height"
                    :width="msgDim.width"
                    min-width="20"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="py-0">
            <v-col class="d-flex align-center pb-0">
              <p class="custom-font descriptor mk-blue">
                {{ !$language ? "Present:" : "উপস্থিত:" }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-avatar
                tile
                color="#0099dc"
                class="white--text rounded"
                :size="msgDim.width"
              >
                <span>{{ presentStudent }}</span>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="py-0">
            <v-col class="d-flex align-center pb-0">
              <p class="custom-font descriptor mk-orange">
                {{ !$language ? "Absent:" : "অনুপস্থিত:" }}
              </p>
            </v-col>
            <v-col class="pb-0">
              <v-row no-gutters>
                <v-col cols="5" class="pa-0">
                  <v-avatar
                    tile
                    color="#fc6222"
                    class="white--text rounded"
                    :size="msgDim.width"
                  >
                    <span>{{ absentStudent }}</span>
                  </v-avatar>
                </v-col>
                <v-col class="pa-0 d-flex justify-space-between">
                  <v-btn
                    color="white"
                    class="mk-orange"
                    :height="msgDim.height"
                    :width="msgDim.width"
                    min-width="20"
                  >
                    <v-icon>mdi-chat</v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    class="mk-orange"
                    :height="msgDim.height"
                    :width="msgDim.width"
                    min-width="20"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="screenSize < 900">
          <v-col>
            <v-row no-gutters>
              <v-col
                :cols="screenSize < 600 ? '12' : '4'"
                class="d-flex align-center justify-center"
                ><p class="custom-font descriptor">
                  {{ !$language ? "Total:" : "মোট:" }}
                </p></v-col
              >
              <v-col class="d-flex align-center justify-space-between">
                <v-avatar
                  tile
                  color="black"
                  class="white--text rounded"
                  :size="msgDim.width"
                >
                  <span>{{ totalStudent }}</span>
                </v-avatar>
                <v-btn
                  color="white"
                  class="black--text"
                  :height="msgDim.height"
                  :width="msgDim.width"
                  min-width="20"
                >
                  <v-icon>mdi-chat</v-icon>
                </v-btn>
                <v-btn
                  color="white"
                  class="black--text"
                  :height="msgDim.height"
                  :width="msgDim.width"
                  min-width="20"
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row no-gutters>
              <v-col
                :cols="screenSize < 600 ? '12' : '4'"
                class="d-flex align-center justify-center"
              >
                <p class="custom-font descriptor mk-orange">
                  {{ !$language ? "Absent" : "অনুপস্থিত" }}:
                </p>
              </v-col>
              <v-col class="d-flex align-center justify-space-between">
                <v-avatar
                  tile
                  color="#fc6222"
                  class="white--text rounded"
                  :size="msgDim.width"
                >
                  <span>{{ absentStudent }}</span>
                </v-avatar>
                <v-btn
                  color="white"
                  class="mk-orange"
                  :height="msgDim.height"
                  :width="msgDim.width"
                  min-width="20"
                >
                  <v-icon>mdi-chat</v-icon>
                </v-btn>
                <v-btn
                  color="white"
                  class="mk-orange"
                  :height="msgDim.height"
                  :width="msgDim.width"
                  min-width="20"
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <transition>
          <v-row
            v-if="enableEditing"
            no-gutters
            :class="
              screenSize < 600 ? 'mt-5' : screenSize < 900 ? 'mt-10' : 'mt-15'
            "
          >
            <v-col class="pr-3">
              <v-btn
                block
                :x-large="screenSize >= 1600"
                :large="screenSize >= 900 && screenSize < 1600"
                :small="screenSize < 600"
                outlined
                color="#fc6222"
                class="custom-font"
                @click="toggleEditing()"
              >
                <span>{{ !$language ? "Cancel" : "বাতিল করুন" }}</span>
              </v-btn>
            </v-col>
            <v-col class="pl-3">
              <v-btn
                block
                :x-large="screenSize >= 1600"
                :large="screenSize >= 900 && screenSize < 1600"
                :small="screenSize < 600"
                :loading="saving['status']"
                color="#0099dc"
                class="white--text custom-font"
                @click="updateAttendance(updateTarget)"
              >
                <span>{{ !$language ? "Save" : "সেভ করুন" }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </transition>
      </div>
      <v-row v-if="!enableEditing" no-gutters class="py-3" justify="end">
        <transition>
          <v-btn
            :x-large="screenSize >= 1600"
            :large="screenSize >= 900 && screenSize < 1600"
            :x-small="screenSize < 600"
            color="#0099dc"
            class="white--text"
            depressed
            @click="switchf()"
            ><span>{{
              !$language
                ? "See Full Attendance Details"
                : "সম্পূর্ণ উপস্থিতির বিবরণ দেখুন"
            }}</span></v-btn
          >
        </transition>
      </v-row>
    </div>
  </div>
</template>

<script>
import MkCheckBox from "./MkCheckBox.vue";
import MkDateSlider from "./MkDateSlider.vue";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "AttendanceComponent",
  inject: ["$courseId", "$batchId"],
  components: { MkDateSlider, MkCheckBox, NoContentComponent },
  props: ["batchType", "batchStudentsObject", "allowEdit"],
  data() {
    return {
      currentDate: "",
      currentWeekDay: "",
      currentDateBN: "",
      currentWeekDayBN: "",
      totalStudent: 0,
      presentStudent: 0,
      absentStudent: 0,
      enableEditing: false,
      today: "",
      targetDate: "",
      updateTarget: "",
      dateList: [],
      drawSlider: false,
      saving: {},
      studentList: [],
      attendanceList: {},
      colors: ["pink", "purple", "indigo", "teal", "amber"],
    };
  },
  computed: {
    batchTypeBN() {
      if (this.batchType == "Online") {
        return "অনলাইন";
      } else {
        return "ব্যক্তিগত ভাবে";
      }
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    checkDim() {
      let dim =
        this.screenSize < 600
          ? 12
          : this.screenSize < 900
          ? 14
          : this.screenSize < 1600
          ? 18
          : 24;
      return { height: dim, width: dim };
    },
    avatarSize() {
      return this.screenSize < 600
        ? 21
        : this.screenSize < 900
        ? 24
        : this.screenSize < 1600
        ? 36
        : 45;
    },
    msgDim() {
      let dim =
        this.screenSize < 600
          ? 24
          : this.screenSize < 900
          ? 36
          : this.screenSize < 1600
          ? 40
          : 48;
      return { height: dim, width: dim };
    },
    batchStudents() {
      return this.$store.getters["batch/batchStudents"];
    },
    noContentMessage() {
      return this.$language
        ? "বর্তমানে এই ব্যাচ কোনো শিক্ষার্থী নেই"
        : "Currently there are no students in this batch";
    },
  },
  async created() {
    this.getToday();
    this.getTodayBN();
    await this.getDateList();
    //await this.retrieveBatchStudentInfo();
    await this.populateStudentList();
    this.updateTarget = this.today;
  },
  methods: {
    switchf() {
      this.$emit("switch");
    },
    randomColour() {
      let i = Math.round(Math.random() * 100) % 5;
      return this.colors[i];
    },
    toggleEditing() {
      this.enableEditing = !this.enableEditing;
    },
    toggleActive(index) {
      if (this.studentList[index].absent) {
        this.$set(this.attendanceList, this.studentList[index].id, true);
        this.absentStudent--;
        this.presentStudent++;
      } else {
        this.$delete(this.attendanceList, this.studentList[index].id);
        this.absentStudent++;
        this.presentStudent--;
      }
      this.studentList[index].absent = !this.studentList[index].absent;
    },
    getTodayBN() {
      let z = new Date();
      this.currentDateBN = `${z.toLocaleString("bn-BD", {
        day: "2-digit",
      })}-${z.toLocaleString("bn-BD", {
        month: "short",
      })}-${z.toLocaleString("bn-BD", { year: "numeric" })}`;
      this.currentWeekDayBN = `${z.toLocaleString("bn-BD", {
        weekday: "long",
      })}`;
    },
    getToday() {
      let z = new Date();
      this.currentDate = `${z.toLocaleString("default", {
        day: "2-digit",
      })}-${z.toLocaleString("default", {
        month: "short",
      })}-${z.getFullYear()}`;
      this.currentWeekDay = `${z.toLocaleString("default", {
        weekday: "long",
      })}`;
      this.today = `${z.getFullYear()}-${z.toLocaleString("default", {
        month: "2-digit",
      })}-${z.toLocaleString("default", { day: "2-digit" })}`;
    },
    avatarInitials(nameStr) {
      let chars = nameStr.split(" ");
      let ret = "";
      for (let c of chars) {
        ret += c[0];
      }
      return ret;
    },
    async getDateList() {
      try {
        let out = await this.$store.dispatch("batch/getClassDateList", {
          partner_code: this.$route.params.partner_code,
          course_id: this.$courseId(),
          batch_id: this.$batchId(),
        });
        out.sort();
        this.dateList = out;
        let n = this.dateList.length;
        if (n == 0) this.dateList.push(this.today);
        else if (this.dateList[n - 1] != this.today)
          this.dateList.push(this.today);
        else this.targetDate = this.today;
        this.drawSlider = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async slidehandeler(payload) {
      try {
        if (payload == this.targetDate || payload != this.today) {
          let z = await this.getAttendanceAt(payload);
          this.attendanceList = {};
          this.presentStudent = 0;
          for (let student of this.studentList) {
            if (student.id in z) {
              student.invalid = false;
              student.absent = true;
              if (z[student.id] == "present") {
                student.absent = false;
                this.$set(this.attendanceList, student.id, true);
                this.presentStudent++;
              }
            } else {
              student.invalid = true;
              student.absent = true;
            }
          }
        } else if (payload == this.today) {
          for (let student of this.studentList) {
            student.absent = false;
            student.invalid = false;
            this.$set(this.attendanceList, student.id, true);
            this.presentStudent++;
          }
        }
        this.absentStudent = this.totalStudent - this.presentStudent;
        this.updateTarget = payload;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async getAttendanceAt(date) {
      try {
        return await this.$store.dispatch("batch/retriveAttendance", {
          partner_code: this.$route.params.partner_code,
          course_id: this.$courseId(),
          batch_id: this.$batchId(),
          date: date,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async updateAttendance(date) {
      this.$set(this.saving, "status", false);
      try {
        await this.$store.dispatch("batch/updateAttendance", {
          partner_code: this.$route.params.partner_code,
          course_id: this.$courseId(),
          batch_id: this.$batchId(),
          date: date,
          student_ids: Object.keys(this.attendanceList),
        });
        this.$set(this.saving, "status", true);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.saving, "status", false);
        this.enableEditing = !this.enableEditing;
      }
    },
    async populateStudentList() {
      if (this.targetDate) {
        await this.dataBasedPopulate();
      } else {
        this.defaultPopulate();
      }
      this.totalStudent = this.studentList.length;
      this.presentStudent = this.totalStudent;
    },

    defaultPopulate() {
      let s = this.batchStudentsObject;
      for (let student in s) {
        this.studentList.push({
          id: student,
          name: s[student].name,
          avatar: this.avatarInitials(s[student].name),
          color: this.randomColour(),
          email: s[student].email,
          number: s[student].phone,
          invalid: false,
          absent: false,
        });
        this.$set(this.attendanceList, student, true);
      }
    },

    async dataBasedPopulate() {
      try {
        let s = this.batchStudentsObject;
        let z = await this.getAttendanceAt(this.targetDate);

        for (let student in s) {
          let absent = true,
            invalid = true;
          if (student in z) {
            invalid = false;
            if (z[student] == "present") absent = false;
          }
          this.studentList.push({
            id: student,
            name: s[student].name,
            avatar: this.avatarInitials(s[student].name),
            color: this.randomColour(),
            email: s[student].email,
            number: s[student].phone,
            invalid: invalid,
            absent: absent,
          });
          if (!(invalid && !absent))
            this.$set(this.attendanceList, student, true);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-font {
  font-family: Avenir;
}

.table {
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (max-width: 599px) {
    // width: calc(100vw - 105px);
    width: calc(100vw - 65px);
    height: calc(75vh - 184px);
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    // width: calc(100vw - 304px);
    width: calc(100vw - 65px);
    height: calc(75vh - 300px);
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    width: 560px;
  }

  @media screen and (min-width: 1600px) {
    width: 880px;
  }
}

.header {
  border: 0px solid #00000000;
  border-radius: 10px 10px 0px 0px;
  z-index: 1;

  @media screen and (min-width: 1601px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 599px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.content {
  max-height: 60vh;
  border: 2px solid #ffffff;
  border-top-width: 0px;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-item {
  border-style: solid;
  border-color: #8b8b8b7f;
  border-width: 0px 0px 1px 0px;

  @media screen and (min-width: 1601px) {
    padding: 0px 8px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 0px 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 0px 5px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 3px;
  }
}

.control {
  background-color: white;
  border: 1px solid #8b8b8b7f;
  border-radius: 6px;

  @media screen and (max-width: 599px) {
    width: calc(100vw - 65px);
    padding: 7px;
    margin-top: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    width: calc(100vw - 65px);
    margin-left: 0px;
    margin-top: 10px;
    padding: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    width: 375px;
    margin-left: 50px;
    padding: 15px;
  }

  @media screen and (min-width: 1600px) {
    width: 480px;
    margin-left: 60px;
    padding: 20px;
  }
}

.day-text {
  color: #0099dc;
}

.date-text {
  padding-left: 1em;
}

.control-heading {
  @media screen and (max-width: 599px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 24px;
  }
}

.descriptor {
  font-weight: 600;

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 24px;
  }
}

.mk-orange {
  color: #fc6222;
}

.mk-blue {
  color: #0099dc;
}

.content-text {
  display: inline-block;
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
    max-width: 125px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
    max-width: 60px;
  }
}

.no-effect {
  pointer-events: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
