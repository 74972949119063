var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { staticClass: "rounded-lg sheet-style" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              class: { "py-0": _vm.screenSize < 900 },
              attrs: {
                cols: _vm.colsPartition,
                order: _vm.screenSize < 900 ? "2" : "1",
              },
            },
            [
              _c(
                "div",
                { staticClass: "list-style" },
                [
                  _vm.noContentCondition
                    ? _c("no-content-component", {
                        attrs: { message: _vm.noContentMessage },
                      })
                    : _vm._e(),
                  _vm._l(_vm.studentList, function (student, index) {
                    return _c(
                      "v-card",
                      {
                        key: index,
                        staticClass: "card-style",
                        attrs: { flat: "" },
                      },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "card-padding",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex justify-center align-center avatar-padding",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      size: _vm.avatarSize,
                                      color: student.color,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "info-text" }, [
                                      _vm._v(_vm._s(student.avatar)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              [
                                _c("v-row", { attrs: { "no-gutters": "" } }, [
                                  _c("span", { staticClass: "info-text" }, [
                                    _vm._v(_vm._s(student.name)),
                                  ]),
                                ]),
                                _c("v-row", { attrs: { "no-gutters": "" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "info-text info-number" },
                                    [_vm._v(_vm._s(student.number))]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _c(
            "v-col",
            {
              class: { "py-0": _vm.screenSize < 900 },
              attrs: {
                cols: _vm.colsPartition,
                order: _vm.screenSize < 900 ? "1" : "2",
              },
            },
            [
              _c(
                "div",
                { staticClass: "performance-display" },
                [
                  _vm.screenSize >= 900
                    ? _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "div",
                          { staticClass: "calender-boundry" },
                          [
                            _c("v-date-picker", {
                              attrs: {
                                "no-title": "",
                                "full-width": "",
                                landscape: "",
                                multiple: "",
                                color: "#fc6222",
                                readonly: "",
                              },
                              model: {
                                value: _vm.dates,
                                callback: function ($$v) {
                                  _vm.dates = $$v
                                },
                                expression: "dates",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "control-style", attrs: { flat: "" } },
                        [
                          _c("v-row", { attrs: { "no-gutters": "" } }, [
                            !_vm.$language
                              ? _c(
                                  "span",
                                  { staticClass: "control-day-text" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.currentMonth) +
                                        ", " +
                                        _vm._s(_vm.currentYear)
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "control-day-text" },
                                  [_vm._v(_vm._s(_vm.currentYearBN))]
                                ),
                          ]),
                          _c("v-row", { attrs: { "no-gutters": "" } }, [
                            _c(
                              "span",
                              { staticClass: "attendance-type-text" },
                              [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Attendance Type"
                                      : "উপস্থিতির ধরন"
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "5" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "pointer-events": "none" },
                                      attrs: {
                                        depressed: "",
                                        outlined: "",
                                        block: "",
                                        color: "black",
                                        height: _vm.buttonDim,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "batch-type-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              !_vm.$language
                                                ? `${_vm.batchType}`
                                                : `${_vm.batchTypeBN}`
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-space-around",
                                  attrs: { cols: "7" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "white",
                                        height: _vm.buttonDim,
                                        width: _vm.buttonDim,
                                        "min-width": "25",
                                        icon: _vm.screenSize < 600,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#fc6222",
                                            size: _vm.iconDim,
                                          },
                                        },
                                        [_vm._v("mdi-chat")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "white",
                                        height: _vm.buttonDim,
                                        width: _vm.buttonDim,
                                        "min-width": "25",
                                        icon: _vm.screenSize < 600,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#fc6222",
                                            size: _vm.iconDim,
                                          },
                                        },
                                        [_vm._v("mdi-email")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.screenSize < 900
                                    ? _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ attrs, on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "white",
                                                              height:
                                                                _vm.buttonDim,
                                                              width:
                                                                _vm.buttonDim,
                                                              "min-width": "25",
                                                              icon:
                                                                _vm.screenSize <
                                                                600,
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "black",
                                                              size: _vm.iconDim,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-calendar-month"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            328460418
                                          ),
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              "no-title": "",
                                              multiple: "",
                                              color: "#fc6222",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm.dates,
                                              callback: function ($$v) {
                                                _vm.dates = $$v
                                              },
                                              expression: "dates",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("v-avatar", { attrs: { tile: "" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "present-absent-section",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "present-absent-text",
                                      staticStyle: { color: "#0099dc" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language ? "Present" : "উপস্থিত"
                                        ) + ":"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "white--text rounded",
                                      attrs: {
                                        tile: "",
                                        color: "#0099dc",
                                        size: _vm.buttonDim,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "info-text" }, [
                                        _vm._v("N/A"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "present-absent-text",
                                      staticStyle: { color: "#0099dc" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(!_vm.$language ? "days" : "দিন")
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "1" } }),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "present-absent-text",
                                      staticStyle: { color: "#fc6222" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Absent"
                                            : "অনুপস্থিত"
                                        ) + ":"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "white--text rounded",
                                      attrs: {
                                        tile: "",
                                        color: "#fc6222",
                                        size: _vm.buttonDim,
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "info-text" }, [
                                        _vm._v("N/A"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "present-absent-text",
                                      staticStyle: { color: "#fc6222" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(!_vm.$language ? "days" : "দিন")
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }