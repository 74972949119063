var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "d-flex pa-0 invisible-scrollbar" }, [
    _c(
      "div",
      [
        _c("submission-alert-message", {
          ref: "banner",
          staticClass: "py-3",
          staticStyle: { "max-width": "1100px" },
          attrs: { "should-scroll-to-top": "", message: _vm.bannerMessage },
          model: {
            value: _vm.showAlertBanner,
            callback: function ($$v) {
              _vm.showAlertBanner = $$v
            },
            expression: "showAlertBanner",
          },
        }),
        _c(
          "v-card",
          {
            class: [
              "m-light-card",
              { "border-2 border-solid border-primary": !_vm.isReadOnly },
            ],
          },
          [
            _c("batch-form", {
              ref: "form",
              attrs: { "read-only": _vm.isReadOnly, "all-valid": _vm.ok },
              on: {
                "update:allValid": function ($event) {
                  _vm.ok = $event
                },
                "update:all-valid": function ($event) {
                  _vm.ok = $event
                },
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            }),
            _c("div", { staticClass: "mt-5" }),
            _c(
              "transition",
              [
                !_vm.isReadOnly
                  ? _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "px-3" },
                          [
                            _c(
                              "v-col",
                              {
                                class: [
                                  "d-flex",
                                  _vm.screenSize < 600
                                    ? "justify-space-around"
                                    : " pr-5 justify-end",
                                ],
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    class: { "mr-5": _vm.screenSize >= 900 },
                                    attrs: {
                                      "x-large": _vm.screenSize >= 1600,
                                      large:
                                        _vm.screenSize >= 900 &&
                                        _vm.screenSize < 1600,
                                      depressed: "",
                                      outlined: "",
                                      color: "red accented-3",
                                    },
                                    on: { click: _vm.handleCancel },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("cancel")]),
                                    _c("span", { staticClass: "custom-font" }, [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Cancel"
                                            : "বাতিল করুন"
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "white--text px-10 ml-5 elevation-3",
                                    attrs: {
                                      "x-large": _vm.screenSize >= 1600,
                                      large:
                                        _vm.screenSize >= 900 &&
                                        _vm.screenSize < 1600,
                                      color: "#0099dd",
                                      loading: _vm.saving.status,
                                    },
                                    on: { click: _vm.save },
                                  },
                                  [
                                    _c("span", { staticClass: "custom-font" }, [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Done"
                                            : "সম্পন্ন করুন"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }