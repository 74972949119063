var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "d-flex align-start": _vm.screenSize >= 900 } }, [
    _c("div", { staticClass: "table" }, [
      _c(
        "div",
        { staticClass: "header px-3 elevation-3" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c("span", { staticClass: "content-text" }, [
                            _vm._v(_vm._s(!_vm.$language ? "Avatar" : "ছবি")),
                          ]),
                        ]
                      ),
                      _vm.screenSize >= 900
                        ? _c("v-col", { attrs: { cols: "3" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { staticClass: "d-flex align-center" }, [
                _c("span", { staticClass: "content-text" }, [
                  _vm._v(_vm._s(!_vm.$language ? "Name" : "নাম")),
                ]),
              ]),
              _c("v-col", { staticClass: "d-flex align-center" }, [
                _c("span", { staticClass: "content-text" }, [
                  _vm._v(_vm._s(!_vm.$language ? "Number" : "মোবাইল নাম্বার")),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: _vm.screenSize < 900 ? 3 : 2 } },
                [
                  _c(
                    "v-row",
                    [
                      _vm.screenSize >= 900
                        ? _c("v-col", { attrs: { cols: "2" } })
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c("span", { staticClass: "content-text" }, [
                            _vm._v(
                              _vm._s(!_vm.$language ? "Absent" : "অনুপস্থিত")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content px-4 pt-2" },
        [
          _vm.studentList.length == 0
            ? _c("no-content-component", {
                attrs: { message: _vm.noContentMessage },
              })
            : _vm._e(),
          _vm._l(_vm.studentList, function (student, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass: "content-item",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center justify-center",
                            attrs: { cols: _vm.screenSize < 900 ? 9 : 8 },
                          },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: student.color,
                                  size: _vm.avatarSize,
                                },
                              },
                              [
                                _c("span", { staticClass: "content-text" }, [
                                  _vm._v(_vm._s(student.avatar)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "3" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text text-truncate" }, [
                    _vm._v(_vm._s(student.name)),
                  ]),
                ]),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text text-truncate" }, [
                    _vm._v(_vm._s(student.number)),
                  ]),
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: _vm.screenSize < 900 ? 3 : 2 } },
                  [
                    _c(
                      "v-row",
                      [
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "2" } })
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex align-center justify-center ml-4",
                          },
                          [
                            _c("mk-check-box", {
                              attrs: {
                                disabled: _vm.enableEditing && !student.invalid,
                                color: "#fc6222",
                                index: index,
                                "size-object": _vm.checkDim,
                                "min-width": "12",
                                active: student.absent,
                              },
                              on: { click: _vm.toggleActive },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "control" },
          [
            _vm.screenSize >= 600
              ? _c("h2", { staticClass: "control-heading" }, [
                  _c(
                    "span",
                    { staticClass: "day-text custom-font text-uppercase" },
                    [_vm._v(_vm._s(!_vm.$language ? "Today" : "আজ") + ",")]
                  ),
                  _c("span", { staticClass: "date-text custom-font" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$language
                          ? `${_vm.currentDate}`
                          : `${_vm.currentDateBN}`
                      )
                    ),
                  ]),
                  _c("span", { staticClass: "date-text custom-font" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$language
                          ? `${_vm.currentWeekDay}`
                          : `${_vm.currentWeekDayBN}`
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.screenSize >= 900
              ? _c(
                  "p",
                  { staticClass: "text-body-2 pt-6 custom-font grey--text" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(!_vm.$language ? "Class Type" : "ক্লাস টাইপ") +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                class: { "pb-2": _vm.screenSize < 600 },
                attrs: { "no-gutters": _vm.screenSize < 600 },
              },
              [
                _vm.screenSize < 900
                  ? _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center align-center",
                        attrs: { cols: "3" },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "text-body-2 custom-font grey--text" },
                          [
                            _c("span", { staticClass: "content-text" }, [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language ? "Class Type" : "ক্লাস টাইপ"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "no-effect",
                        attrs: {
                          outlined: "",
                          "x-large": _vm.screenSize >= 1600,
                          large: _vm.screenSize >= 900 && _vm.screenSize < 1600,
                          small: _vm.screenSize < 600,
                          block: "",
                        },
                      },
                      [
                        _c("span", { staticClass: "content-text" }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? `${_vm.batchType}`
                                : `${_vm.batchTypeBN}`
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { class: { "pl-2": _vm.screenSize < 600 } },
                  [
                    !_vm.enableEditing && _vm.allowEdit
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "white--text custom-font",
                            attrs: {
                              color: "#0099dc",
                              "x-large": _vm.screenSize >= 1600,
                              large:
                                _vm.screenSize >= 900 && _vm.screenSize < 1600,
                              small: _vm.screenSize < 600,
                              block: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleEditing()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "content-text" }, [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language
                                    ? "Edit Attendance"
                                    : "উপস্থিতি এডিট করুন"
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.screenSize >= 900
              ? _c(
                  "p",
                  {
                    staticClass: "text-body-2 pt-2 pb-3 custom-font grey--text",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(!_vm.$language ? "Date" : "তারিখ") + " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: _vm.screenSize < 900 ? "7" : "12" } },
                  [
                    _vm.drawSlider
                      ? _c("mk-date-slider", {
                          attrs: { "date-list": _vm.dateList },
                          on: { slide: _vm.slidehandeler },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.screenSize < 900
                  ? _c(
                      "v-col",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                class: [
                                  "d-flex align-center",
                                  _vm.screenSize < 600
                                    ? "justify-end"
                                    : "justify-center",
                                ],
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "custom-font descriptor mk-blue",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language ? "Present" : "উপস্থিত"
                                        ) +
                                        ": "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center justify-end",
                                attrs: { cols: "4" },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text rounded",
                                    attrs: {
                                      tile: "",
                                      color: "#0099dc",
                                      size: _vm.msgDim.width,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.presentStudent)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.screenSize >= 900
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { staticClass: "mt-6 py-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center pb-0" },
                          [
                            _c("p", { staticClass: "custom-font descriptor" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Total Students:"
                                      : "মোট শিক্ষার্থী:"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pb-0" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "white--text rounded",
                                        attrs: {
                                          tile: "",
                                          color: "black",
                                          size: _vm.msgDim.width,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.totalStudent)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "pa-0 d-flex justify-space-between",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "black--text",
                                        attrs: {
                                          color: "white",
                                          height: _vm.msgDim.height,
                                          width: _vm.msgDim.width,
                                          "min-width": "20",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-chat")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "black--text",
                                        attrs: {
                                          color: "white",
                                          height: _vm.msgDim.height,
                                          width: _vm.msgDim.width,
                                          "min-width": "20",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-email")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center pb-0" },
                          [
                            _c(
                              "p",
                              { staticClass: "custom-font descriptor mk-blue" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language ? "Present:" : "উপস্থিত:"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pb-0" },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "white--text rounded",
                                attrs: {
                                  tile: "",
                                  color: "#0099dc",
                                  size: _vm.msgDim.width,
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.presentStudent))])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center pb-0" },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "custom-font descriptor mk-orange",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language ? "Absent:" : "অনুপস্থিত:"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pb-0" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "5" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "white--text rounded",
                                        attrs: {
                                          tile: "",
                                          color: "#fc6222",
                                          size: _vm.msgDim.width,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.absentStudent)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "pa-0 d-flex justify-space-between",
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mk-orange",
                                        attrs: {
                                          color: "white",
                                          height: _vm.msgDim.height,
                                          width: _vm.msgDim.width,
                                          "min-width": "20",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-chat")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mk-orange",
                                        attrs: {
                                          color: "white",
                                          height: _vm.msgDim.height,
                                          width: _vm.msgDim.width,
                                          "min-width": "20",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-email")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.screenSize < 900
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: {
                                  cols: _vm.screenSize < 600 ? "12" : "4",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "custom-font descriptor" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language ? "Total:" : "মোট:"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between",
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text rounded",
                                    attrs: {
                                      tile: "",
                                      color: "black",
                                      size: _vm.msgDim.width,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.totalStudent)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "black--text",
                                    attrs: {
                                      color: "white",
                                      height: _vm.msgDim.height,
                                      width: _vm.msgDim.width,
                                      "min-width": "20",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-chat")])],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "black--text",
                                    attrs: {
                                      color: "white",
                                      height: _vm.msgDim.height,
                                      width: _vm.msgDim.width,
                                      "min-width": "20",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-email")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: {
                                  cols: _vm.screenSize < 600 ? "12" : "4",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "custom-font descriptor mk-orange",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Absent"
                                            : "অনুপস্থিত"
                                        ) +
                                        ": "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between",
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text rounded",
                                    attrs: {
                                      tile: "",
                                      color: "#fc6222",
                                      size: _vm.msgDim.width,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.absentStudent)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mk-orange",
                                    attrs: {
                                      color: "white",
                                      height: _vm.msgDim.height,
                                      width: _vm.msgDim.width,
                                      "min-width": "20",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-chat")])],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mk-orange",
                                    attrs: {
                                      color: "white",
                                      height: _vm.msgDim.height,
                                      width: _vm.msgDim.width,
                                      "min-width": "20",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-email")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "transition",
              [
                _vm.enableEditing
                  ? _c(
                      "v-row",
                      {
                        class:
                          _vm.screenSize < 600
                            ? "mt-5"
                            : _vm.screenSize < 900
                            ? "mt-10"
                            : "mt-15",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pr-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-font",
                                attrs: {
                                  block: "",
                                  "x-large": _vm.screenSize >= 1600,
                                  large:
                                    _vm.screenSize >= 900 &&
                                    _vm.screenSize < 1600,
                                  small: _vm.screenSize < 600,
                                  outlined: "",
                                  color: "#fc6222",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleEditing()
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Cancel" : "বাতিল করুন"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pl-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text custom-font",
                                attrs: {
                                  block: "",
                                  "x-large": _vm.screenSize >= 1600,
                                  large:
                                    _vm.screenSize >= 900 &&
                                    _vm.screenSize < 1600,
                                  small: _vm.screenSize < 600,
                                  loading: _vm.saving["status"],
                                  color: "#0099dc",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateAttendance(
                                      _vm.updateTarget
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(!_vm.$language ? "Save" : "সেভ করুন")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.enableEditing
          ? _c(
              "v-row",
              {
                staticClass: "py-3",
                attrs: { "no-gutters": "", justify: "end" },
              },
              [
                _c(
                  "transition",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: {
                          "x-large": _vm.screenSize >= 1600,
                          large: _vm.screenSize >= 900 && _vm.screenSize < 1600,
                          "x-small": _vm.screenSize < 600,
                          color: "#0099dc",
                          depressed: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.switchf()
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !_vm.$language
                                ? "See Full Attendance Details"
                                : "সম্পূর্ণ উপস্থিতির বিবরণ দেখুন"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }