<template>
  <v-sheet class="rounded-lg sheet-style">
    <v-row>
      <v-col
        :cols="colsPartition"
        :order="screenSize < 900 ? '2' : '1'"
        :class="{ 'py-0': screenSize < 900 }"
      >
        <div class="list-style">
          <no-content-component v-if="noContentCondition" :message="noContentMessage"></no-content-component>
          <v-card
            v-for="(student, index) in studentList"
            :key="index"
            flat
            class="card-style"
          >
            <v-row no-gutters class="card-padding">
              <v-col
                cols="2"
                class="d-flex justify-center align-center avatar-padding"
              >
                <v-avatar
                  :size="avatarSize"
                  :color="student.color"
                  class="white--text"
                >
                  <span class="info-text">{{ student.avatar }}</span>
                </v-avatar>
              </v-col>
              <v-col>
                <v-row no-gutters
                  ><span class="info-text">{{ student.name }}</span></v-row
                >
                <v-row no-gutters
                  ><span class="info-text info-number">{{
                    student.number
                  }}</span></v-row
                >
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
      <v-col
        :cols="colsPartition"
        :order="screenSize < 900 ? '1' : '2'"
        :class="{ 'py-0': screenSize < 900 }"
      >
        <div class="performance-display">
          <v-row v-if="screenSize >= 900" no-gutters>
            <div class="calender-boundry">
              <v-date-picker
                v-model="dates"
                no-title
                full-width
                landscape
                multiple
                color="#fc6222"
                readonly
              ></v-date-picker>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-card flat class="control-style">
              <v-row no-gutters>
                <span v-if="!$language" class="control-day-text"
                  >{{ currentMonth }}, {{ currentYear }}</span
                >
                <span v-else class="control-day-text">{{ currentYearBN }}</span>
              </v-row>
              <v-row no-gutters>
                <span class="attendance-type-text">{{
                  !$language ? "Attendance Type" : "উপস্থিতির ধরন"
                }}</span>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-btn
                    depressed
                    outlined
                    block
                    color="black"
                    :height="buttonDim"
                    style="pointer-events: none"
                  >
                    <span class="batch-type-text">{{
                      !$language ? `${batchType}` : `${batchTypeBN}`
                    }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="7" class="d-flex justify-space-around">
                  <v-btn
                    color="white"
                    :height="buttonDim"
                    :width="buttonDim"
                    min-width="25"
                    :icon="screenSize < 600"
                  >
                    <v-icon color="#fc6222" :size="iconDim">mdi-chat</v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    :height="buttonDim"
                    :width="buttonDim"
                    min-width="25"
                    :icon="screenSize < 600"
                  >
                    <v-icon color="#fc6222" :size="iconDim">mdi-email</v-icon>
                  </v-btn>
                  <v-menu v-if="screenSize < 900">
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        color="white"
                        :height="buttonDim"
                        :width="buttonDim"
                        min-width="25"
                        :icon="screenSize < 600"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="black" :size="iconDim"
                          >mdi-calendar-month</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      no-title
                      multiple
                      color="#fc6222"
                      readonly
                    ></v-date-picker>
                  </v-menu>

                  <v-avatar v-else tile> </v-avatar>
                </v-col>
              </v-row>
              <v-row no-gutters class="present-absent-section">
                <v-col
                  cols="5"
                  class="d-flex justify-space-between align-center"
                >
                  <span class="present-absent-text" style="color: #0099dc"
                    >{{ !$language ? "Present" : "উপস্থিত" }}:</span
                  >
                  <v-avatar
                    tile
                    color="#0099dc"
                    class="white--text rounded"
                    :size="buttonDim"
                  >
                    <span class="info-text">N/A</span>
                  </v-avatar>
                  <span class="present-absent-text" style="color: #0099dc">{{
                    !$language ? "days" : "দিন"
                  }}</span>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col
                  cols="5"
                  class="d-flex justify-space-between align-center"
                >
                  <span class="present-absent-text" style="color: #fc6222"
                    >{{ !$language ? "Absent" : "অনুপস্থিত" }}:</span
                  >
                  <v-avatar
                    tile
                    color="#fc6222"
                    class="white--text rounded"
                    :size="buttonDim"
                  >
                    <span class="info-text">N/A</span>
                  </v-avatar>
                  <span class="present-absent-text" style="color: #fc6222">{{
                    !$language ? "days" : "দিন"
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import NoContentComponent from "/global/components/NoContentComponent"

export default {
  name: "DetailedAttendance",
  inject: ["$courseId", "$batchId"],
  props: ["batchType", "batchStudentsObject"],
  components: {NoContentComponent},
  data() {
    return {
      studentList: [],
      colors: ["pink", "purple", "indigo", "teal", "amber"],
      dates: [],
      currentMonth: {},
      currentYear: {},
      currentMonthBN: {},
      currentYearBN: {}
    };
  },
  computed: {
    batchTypeBN() {
      if (this.batchType == "Online") {
        return "অনলাইন";
      } else {
        return "ব্যক্তিগত ভাবে";
      }
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    colsPartition() {
      return this.screenSize < 900 ? 12 : 6;
    },
    avatarSize() {
      return this.screenSize < 600
        ? 21
        : this.screenSize < 900
        ? 24
        : this.screenSize < 1600
        ? 36
        : 45;
    },
    buttonDim() {
      let s = this.screenSize;
      return s < 600 ? 25 : s < 1601 ? 40 : 50;
    },
    iconDim() {
      return Math.max(this.buttonDim * 0.6, 20);
    },
    noContentCondition(){
      return this.studentList.length == 0;
    },
    noContentMessage() {
      return this.$language
        ? "বর্তমানে এই ব্যাচ কোনো শিক্ষার্থী নেই"
        : "Currently there are no students in this batch";
    },
  },
  created() {
    this.populateList();
    this.getMonthYear();
    this.getMonthYearBN();
  },
  methods: {
    randomColour() {
      let i = Math.round(Math.random() * 100) % 5;
      return this.colors[i];
    },
    avatarInitials(nameStr) {
      let chars = nameStr.split(" ");
      let ret = "";
      for (let c of chars) {
        ret += c[0];
      }
      return ret;
    },
    populateList() {
      let s = this.batchStudentsObject;
      for (let student in s) {
        this.studentList.push({
          id: student,
          name: s[student].name,
          avatar: this.avatarInitials(s[student].name),
          color: this.randomColour(),
          number: s[student].phone
        });
      }
    },
    getMonthYear() {
      let today = new Date();
      this.currentMonth = today.toLocaleString("default", { month: "long" });
      this.currentYear = today.getFullYear();
    },
    getMonthYearBN() {
      let today = new Date();
      this.currentMonthBN = today.toLocaleString("bn-BD", { month: "long" });
      this.currentYearBN = today.toLocaleString("bn-BD", { dateStyle: "long" });
    }
  }
};
</script>

<style scoped lang="scss">
.sheet-style {
  @media screen and (min-width: 1601px) {
    padding: 40px 50px;
    padding-bottom: 20px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 20px 30px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 0px;
    background-color: transparent;
  }

  @media screen and (max-width: 599px) {
    padding: 0px;
    background-color: transparent;
  }
}

.list-style {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-style {
  border: 1px solid #0099dc;
  color: black;

  :hover {
    background-color: #0099dc;
    color: white;
  }

  @media screen and (min-width: 1601px) {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-bottom: 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 599px) {
    margin-bottom: 3px;
  }
}

.card-padding {
  @media screen and (min-width: 1601px) {
    padding: 10px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
  }

  @media screen and (max-width: 599px) {
  }
}

.avatar-padding {
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 15px 0px;
  }

  @media screen and (max-width: 599px) {
    padding: 10px 0px;
  }
}

.info-text {
  font-family: Avenir, Arial, Helvetica, sans-serif;
  font-weight: 500;

  @media screen and (min-width: 1601px) {
    font-size: 16px;
    padding: 5px 0px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
    padding: 3px 0px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
    padding: 3px 0px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    padding: 2px 0px;
  }
}

.card-style:not(:hover) .info-number {
  color: #a4a4a4;
}

.card-style:hover .info-number:not(:hover) {
  color: white;
}

.performance-display {
  @media screen and (min-width: 1601px) {
    padding-left: 50px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding-left: 30px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
  }

  @media screen and (max-width: 599px) {
  }
}

.calender-boundry {
  border: 2px solid #d4d4d4;
  border-radius: 6px;

  @media screen and (min-width: 1601px) {
    padding: 10px 30px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 5px 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
  }

  @media screen and (max-width: 599px) {
  }
}

.control-style {
  border: 2px solid #d4d4d4;
  border-radius: 6px;
  width: 100%;

  @media screen and (min-width: 1601px) {
    padding: 30px;
    padding-top: 20px;
    margin-top: 30px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 20px;
    padding-top: 10px;
    margin-top: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 599px) {
    padding: 5px 8px;
    margin-bottom: 5px;
  }
}

.control-calander {
  visibility: none;
}

.control-day-text {
  color: #0099dc;
  font-weight: 600;

  @media screen and (min-width: 1601px) {
    font-size: 22px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.attendance-type-text {
  color: #b4b4b4;
  padding: 3px 0px;
  @media screen and (min-width: 1601px) {
    font-size: 14px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.batch-type-text {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.present-absent-section {
  @media screen and (min-width: 1601px) {
    padding-top: 20px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 10px;
  }

  @media screen and (max-width: 599px) {
    padding-top: 5px;
  }
}

.present-absent-text {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
</style>
