<template>
  <v-container class="d-flex pa-0 invisible-scrollbar">
    <div>
      <submission-alert-message
        should-scroll-to-top
        ref="banner"
        class="py-3"
        v-model="showAlertBanner"
        :message="bannerMessage"
        style="max-width: 1100px"
      ></submission-alert-message>
      <v-card
        :class="[
          'm-light-card',
          { 'border-2 border-solid border-primary': !isReadOnly },
        ]"
      >
        <batch-form
          ref="form"
          v-model="formData"
          :read-only="isReadOnly"
          :all-valid.sync="ok"
        ></batch-form>
        <div class="mt-5"></div>
        <transition>
          <v-card-actions v-if="!isReadOnly">
            <v-row class="px-3">
              <v-col
                :class="[
                  'd-flex',
                  screenSize < 600
                    ? 'justify-space-around'
                    : ' pr-5 justify-end',
                ]"
              >
                <v-btn
                  :x-large="screenSize >= 1600"
                  :large="screenSize >= 900 && screenSize < 1600"
                  depressed
                  outlined
                  color="red accented-3"
                  :class="{ 'mr-5': screenSize >= 900 }"
                  @click="handleCancel"
                >
                  <v-icon>cancel</v-icon>
                  <span class="custom-font">{{
                    !$language ? "Cancel" : "বাতিল করুন"
                  }}</span>
                </v-btn>
                <v-btn
                  :x-large="screenSize >= 1600"
                  :large="screenSize >= 900 && screenSize < 1600"
                  color="#0099dd"
                  class="white--text px-10 ml-5 elevation-3"
                  :loading="saving.status"
                  @click="save"
                >
                  <span class="custom-font">{{
                    !$language ? "Done" : "সম্পন্ন করুন"
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </transition>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import BatchForm from "#ecf/batch/components/BatchForm";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "BatchEditComponent",
  components: { BatchForm, SubmissionAlertMessage },
  props: ["isReadOnly", "batchObject"],
  inject: ["$courseId", "$batchId"],
  data() {
    return {
      ok: true,
      formData: undefined,
      saving: {},
      showAlertBanner: false,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    bannerMessage() {
      return this.$language
        ? "সকল বাধ্যতা মূলক তথ্য প্রদান করুন"
        : "Please fill out all mandatory fields";
    },
  },
  created() {
    this.populateFormData();
  },
  watch: {
    ok(value){
      if(value) this.showAlertBanner = false;
    }
  },
  methods: {
    async save() {
      //this is where update function is applied
      if (this.saving?.status) return;

      if (!this.ok) {
        await this.$refs.form.validate();
        if (!this.showAlertBanner) this.showAlertBanner = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }

      this.$set(this.saving, "status", false);
      try {
        this.$set(this.saving, "status", true);
        await this.$store.dispatch("batch/updateBatch", {
          courseId: this.$courseId(),
          batchId: this.$batchId(),
          batchData: this.formData,
        });
        this.$emit("cancel_edit");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.saving, "status", false);
      }
    },
    populateFormData() {
      let elems = this.batchObject.teacher_info.split("#");
      let teacher_id = "";
      if (this.batchObject.is_course_instructor) {
        teacher_id = `${elems[0]}-self#${elems[1]}`;
      } else {
        teacher_id = `${elems[0]}#${elems[1]}`;
      }
      let price =
        this.batchObject.price !== undefined ? this.batchObject.price : 0;
      this.formData = {
        summary: this.batchObject.summary,
        description: this.batchObject.description,
        batch_type: this.batchObject.batch_type,
        payment_type: this.batchObject.payment_type,
        price: price,
        title: this.batchObject.batch_title,
        begin_date: this.batchObject.begin_date,
        end_date: this.batchObject.end_date,
        teacher_id: teacher_id,
        schedule: {},
      };
      let temp = {
        Saturday: undefined,
        Sunday: undefined,
        Monday: undefined,
        Tuesday: undefined,
        Wednesday: undefined,
        Thursday: undefined,
        Friday: undefined,
      };

      for (let item of this.batchObject.days) {
        //"Sunday#13:00#14:00" -> { "Sunday": { "from": { "hour": 2, "minute": 0, "amPm": "PM" }, "to": { "hour": 2, "minute": 45, "amPm": "PM" } } }
        let elems = item.split("#");
        this.$set(temp, elems[0], {
          from: this.formatTime(elems[1]),
          to: this.formatTime(elems[2]),
        });
      }

      this.$set(this.formData, "schedule", temp);
    },
    formatTime(timeString) {
      let time = timeString.split(":");
      let hour = parseInt(time[0]);
      let minute = parseInt(time[1]);
      let amPm = "AM";
      if (hour > 12) {
        hour = hour - 12;
        amPm = "PM";
      }
      let out = {
        hour: hour,
        minute: minute,
        amPm: amPm,
      };

      return out;
    },
    handleCancel() {
      this.$emit("cancel_edit");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
