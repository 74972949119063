<template>
  <v-container>
    <PageTitle
      v-if="!attendanceSwitch"
      :page-title="!$language ? 'Batch Details' : 'ব্যাচের বিবরণ'"
      class="mt-5"
    >
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="
            () =>
              $router.replace({
                name: 'batches',
              })
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </PageTitle>
    <template v-if="!render_now">
      <div id="loading-wrapper">
        <div id="loading-text">LOADING</div>
        <div id="loading-content"></div>
      </div>
    </template>
    <div v-else>
      <div v-if="screenSize >= 900 && !attendanceSwitch" class="mt-10"></div>
      <div
        v-if="screenSize < 900"
        :class="[
          'mt-5 d-flex justify-end align-start edit-control',
          { 'edit-control-viz': !controlButtonShowCondition },
        ]"
      >
        <v-btn
          :x-large="screenSize >= 1600"
          :large="screenSize >= 900 && screenSize < 1600"
          :x-small="screenSize < 600"
          depressed
          color="#0099dc"
          class="white--text text-uppercase mr-5"
          min-height="25"
          @click="enableEditing()"
        >
          <v-icon :small="screenSize < 600">mdi-pencil</v-icon>
          <span class="pl-2">{{ !$language ? "Edit" : "এডিট করুন" }}</span>
        </v-btn>
        <dialog-delete item-text="This Batch" @confirm-remove="removeBatch">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :x-small="screenSize < 600"
              depressed
              color="#ff5252"
              class="white--text text-uppercase"
              min-height="25"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :small="screenSize < 600">mdi-delete</v-icon>
              <span class="pl-2">{{
                !$language ? "Delete" : "ডিলিট করুন"
              }}</span>
            </v-btn>
          </template>
        </dialog-delete>
      </div>
      <div v-if="!attendanceSwitch" class="d-flex">
        <div class="tab-nav mb-10">
          <v-tabs v-model="tab" background-color="#ffffff00">
            <v-tab class="tab-style"
              ><span class="custom-font">{{
                !$language ? "Details" : "বিবরণ"
              }}</span></v-tab
            >
            <v-tab class="tab-style"
              ><span class="custom-font">{{
                !$language ? "Student List" : "শিক্ষার্থী লিস্ট"
              }}</span></v-tab
            >
            <!-- <v-tab class="tab-style"
            ><span class="custom-font">Announcements</span></v-tab
          > -->
            <v-tab class="tab-style"
              ><span class="custom-font">{{
                !$language ? "Attendance" : "উপস্থিতি"
              }}</span></v-tab
            >
          </v-tabs>
        </div>
        <div
          v-if="screenSize >= 900"
          :class="[
            'd-flex justify-end align-start edit-control',
            { 'edit-control-viz': tab > 0 },
          ]"
        >
          <div v-if="controlButtonShowCondition" id="edit-control">
            <v-btn
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              depressed
              color="#0099dc"
              class="white--text text-uppercase mr-5"
              @click="enableEditing()"
            >
              <v-icon>mdi-pencil</v-icon>
              <span class="pl-2">{{ !$language ? "Edit" : "এডিট করুন" }}</span>
            </v-btn>
            <dialog-delete item-text="This Batch" @confirm-remove="removeBatch">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :x-large="screenSize >= 1600"
                  :large="screenSize >= 900 && screenSize < 1600"
                  depressed
                  color="#ff5252"
                  class="white--text text-uppercase"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                  <span class="pl-2">{{
                    !$language ? "Delete" : "ডিলিট করুন"
                  }}</span>
                </v-btn>
              </template>
            </dialog-delete>
          </div>
        </div>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item class="clear-background-item">
          <batch-edit-component
            :is-read-only="!editEnable"
            :batch-object="batch"
            @cancel_edit="editEnable = !editEnable"
          ></batch-edit-component>
        </v-tab-item>
        <v-tab-item class="clear-background-item">
          <student-management-component
            :allow-edit="batch.is_course_owner"
          ></student-management-component>
        </v-tab-item>
        <v-tab-item class="clear-background-item">
          <attendance-manager
            :batch-type="batch.batch_type"
            :allow-edit="batch.is_course_owner"
            @switch="handleSwitch"
          ></attendance-manager>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import StudentManagementComponent from "#ecf/batch/components/StudentManagementComponent.vue";
import AttendanceManager from "#ecf/batch/components/AttendanceManager.vue";
import DialogDelete from "@ecf/global/components/DialogDelete.vue";
import batchService from "#ecf/batch/BatchApi";
import BatchEditComponent from "../../modules/batch/components/BatchEditComponent.vue";

export default {
  components: {
    StudentManagementComponent,
    AttendanceManager,
    DialogDelete,
    BatchEditComponent,
  },
  props: {
    batchId: {
      retuired: true,
      type: String,
    },
    courseId: {
      retuired: true,
      type: String,
    },
    directEdit: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: null,
      editEnable: false,
      popUpActive: false,
      batch: {},
      render_now: false,
      addStudentState: false,
      attendanceSwitch: false,
      removing: false,
    };
  },
  provide() {
    return {
      $courseId: () => {
        return this.courseId;
      },
      $batchId: () => {
        return this.batchId;
      },
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    dialogWidth() {
      return this.screenSize < 600
        ? 305
        : this.screenSize < 900
        ? 500
        : this.screenSize < 1600
        ? 950
        : 1400;
    },
    controlButtonShowCondition() {
      return this.tab == 0 && this.batch.is_course_owner;
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.render_now = false;
    await this.retrieveBatch();
    if (this.directEdit) {
      this.tab = 0;
      this.enableEditing();
    }
  },
  methods: {
    logger(val) {},
    handleSwitch() {
      this.attendanceSwitch = !this.attendanceSwitch;
    },
    enableEditing() {
      this.editEnable = true;
    },
    addNewStudents() {
      if (!this.batch.is_course_owner) {
        this.$root.$emit("alert", [
          undefined,
          "Only Batch Owners are allowed to add students",
        ]);
        return;
      }
      // this.addStudentState = !this.addStudentState;
      this.addStudentState = true;
    },
    async retrieveBatch() {
      try {
        this.batch = await batchService.retrieveBatchDetails(
          this.$route.params.partner_code,
          this.courseId,
          this.batchId
        );
        this.render_now = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async removeBatch(payload) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      if (this.removing) return;
      if (!this.batch.is_course_owner) {
        this.$root.$emit("alert", [
          undefined,
          "Only Batch Owners are allowed to delete",
        ]);
        return;
      }
      try {
        this.removing = true;
        await batchService.delete({ batch_id: this.batchId }, this.courseId);
        await this.$store.dispatch("batch/list", {
          courseId: this.courseId,
          partner_code:
            this.$store.state.organization.currentOrganization.partner_code,
          refresh: true,
        });
        this.$router.push({
          name: "batches",
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.removing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-nav {
  // transition: margin 1s ease;
  @media screen and (max-width: 599px) {
    //width: 275px;
    width: 100vw;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    //width: 495px;
    width: 100vw;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    width: 500px;
  }

  @media screen and (min-width: 1600px) {
    width: 500px;
  }
}

.edit-control {
  // transition: margin 1s ease;
  @media screen and (max-width: 899px) {
    padding-bottom: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    width: 500px;
  }

  @media screen and (min-width: 1600px) {
    width: 500px;
  }
}

.tab-nav-details {
  @media screen and (min-width: 1600px) {
    margin-left: 188px;
  }
}

.edid-control-details {
  @media screen and (min-width: 1600px) {
    margin-right: 188px;
  }
}

.edit-control-viz {
  visibility: hidden;
  pointer-events: none;
}

.custom-font {
  font-family: Arial, Helvetica, sans-serif;
}

.clear-background-item {
  background-color: #f3f7ff;
}

.tab-style {
  font-family: avenir;
  text-transform: none;
  justify-content: center;
  align-items: end;
  padding: 0em;
  padding-bottom: 0.5em;
  min-width: 30px;
  width: auto;
  @media screen and (max-width: 599px) {
    // padding-left: 9px;
    // padding-right: 9px;
    margin-right: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    // padding-left: 10px;
    // padding-right: 10px;
    margin-right: 10px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    // padding-left: 25px;
    // padding-right: 25px;
    margin-right: 15px;
  }

  @media screen and (min-width: 1600px) {
    // padding-left: 50px;
    // padding-right: 50px;
    margin-right: 30px;
  }
}

.v-tab {
  @media screen and (max-width: 599px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 16px;
  }
}
</style>
