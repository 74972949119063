var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _vm.tab == 1
            ? _c(
                "div",
                { staticClass: "mb-5" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 mb-2",
                      attrs: { color: "black", icon: "", large: "" },
                      on: { click: _vm.handle },
                    },
                    [_c("v-icon", [_vm._v("arrow_back")])],
                    1
                  ),
                  _c("span", { staticClass: "fake-title" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$language
                          ? "See Full Attendance"
                          : "সম্পূর্ণ উপস্থিতি দেখুন"
                      )
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { staticClass: "clear-background-item" },
                [
                  _c("attendance-component", {
                    attrs: {
                      "batch-type": _vm.batchType,
                      "batch-students-object": _vm.students,
                      "allow-edit": _vm.allowEdit,
                    },
                    on: { switch: _vm.handle },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { staticClass: "clear-background-item" },
                [
                  _c("detailed-attendance", {
                    attrs: {
                      "batch-type": _vm.batchType,
                      "batch-students-object": _vm.students,
                    },
                    on: { switch: _vm.handle },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "d-flex align-center justify-center",
          staticStyle: { height: "200px" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }