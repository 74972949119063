var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      !_vm.attendanceSwitch
        ? _c("PageTitle", {
            staticClass: "mt-5",
            attrs: {
              "page-title": !_vm.$language ? "Batch Details" : "ব্যাচের বিবরণ",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "black", icon: "", large: "" },
                          on: {
                            click: () =>
                              _vm.$router.replace({
                                name: "batches",
                              }),
                          },
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4144332633
            ),
          })
        : _vm._e(),
      !_vm.render_now
        ? [
            _c("div", { attrs: { id: "loading-wrapper" } }, [
              _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
              _c("div", { attrs: { id: "loading-content" } }),
            ]),
          ]
        : _c(
            "div",
            [
              _vm.screenSize >= 900 && !_vm.attendanceSwitch
                ? _c("div", { staticClass: "mt-10" })
                : _vm._e(),
              _vm.screenSize < 900
                ? _c(
                    "div",
                    {
                      class: [
                        "mt-5 d-flex justify-end align-start edit-control",
                        { "edit-control-viz": !_vm.controlButtonShowCondition },
                      ],
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text text-uppercase mr-5",
                          attrs: {
                            "x-large": _vm.screenSize >= 1600,
                            large:
                              _vm.screenSize >= 900 && _vm.screenSize < 1600,
                            "x-small": _vm.screenSize < 600,
                            depressed: "",
                            color: "#0099dc",
                            "min-height": "25",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.enableEditing()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: _vm.screenSize < 600 } },
                            [_vm._v("mdi-pencil")]
                          ),
                          _c("span", { staticClass: "pl-2" }, [
                            _vm._v(
                              _vm._s(!_vm.$language ? "Edit" : "এডিট করুন")
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("dialog-delete", {
                        attrs: { "item-text": "This Batch" },
                        on: { "confirm-remove": _vm.removeBatch },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "white--text text-uppercase",
                                          attrs: {
                                            "x-large": _vm.screenSize >= 1600,
                                            large:
                                              _vm.screenSize >= 900 &&
                                              _vm.screenSize < 1600,
                                            "x-small": _vm.screenSize < 600,
                                            depressed: "",
                                            color: "#ff5252",
                                            "min-height": "25",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: _vm.screenSize < 600,
                                          },
                                        },
                                        [_vm._v("mdi-delete")]
                                      ),
                                      _c("span", { staticClass: "pl-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Delete"
                                              : "ডিলিট করুন"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1994508455
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.attendanceSwitch
                ? _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "tab-nav mb-10" },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: { "background-color": "#ffffff00" },
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          [
                            _c("v-tab", { staticClass: "tab-style" }, [
                              _c("span", { staticClass: "custom-font" }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Details" : "বিবরণ")
                                ),
                              ]),
                            ]),
                            _c("v-tab", { staticClass: "tab-style" }, [
                              _c("span", { staticClass: "custom-font" }, [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Student List"
                                      : "শিক্ষার্থী লিস্ট"
                                  )
                                ),
                              ]),
                            ]),
                            _c("v-tab", { staticClass: "tab-style" }, [
                              _c("span", { staticClass: "custom-font" }, [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language ? "Attendance" : "উপস্থিতি"
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.screenSize >= 900
                      ? _c(
                          "div",
                          {
                            class: [
                              "d-flex justify-end align-start edit-control",
                              { "edit-control-viz": _vm.tab > 0 },
                            ],
                          },
                          [
                            _vm.controlButtonShowCondition
                              ? _c(
                                  "div",
                                  { attrs: { id: "edit-control" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "white--text text-uppercase mr-5",
                                        attrs: {
                                          "x-large": _vm.screenSize >= 1600,
                                          large:
                                            _vm.screenSize >= 900 &&
                                            _vm.screenSize < 1600,
                                          depressed: "",
                                          color: "#0099dc",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.enableEditing()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [_vm._v("mdi-pencil")]),
                                        _c("span", { staticClass: "pl-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              !_vm.$language
                                                ? "Edit"
                                                : "এডিট করুন"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("dialog-delete", {
                                      attrs: { "item-text": "This Batch" },
                                      on: { "confirm-remove": _vm.removeBatch },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "white--text text-uppercase",
                                                        attrs: {
                                                          "x-large":
                                                            _vm.screenSize >=
                                                            1600,
                                                          large:
                                                            _vm.screenSize >=
                                                              900 &&
                                                            _vm.screenSize <
                                                              1600,
                                                          depressed: "",
                                                          color: "#ff5252",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-delete"),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Delete"
                                                              : "ডিলিট করুন"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3486532065
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { staticClass: "clear-background-item" },
                    [
                      _c("batch-edit-component", {
                        attrs: {
                          "is-read-only": !_vm.editEnable,
                          "batch-object": _vm.batch,
                        },
                        on: {
                          cancel_edit: function ($event) {
                            _vm.editEnable = !_vm.editEnable
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "clear-background-item" },
                    [
                      _c("student-management-component", {
                        attrs: { "allow-edit": _vm.batch.is_course_owner },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "clear-background-item" },
                    [
                      _c("attendance-manager", {
                        attrs: {
                          "batch-type": _vm.batch.batch_type,
                          "allow-edit": _vm.batch.is_course_owner,
                        },
                        on: { switch: _vm.handleSwitch },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }