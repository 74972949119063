<template>
  <v-container v-if="renderNow" class="pa-0">
    <div v-if="tab == 1" class="mb-5">
      <v-btn class="mr-2 mb-2" color="black" icon large @click="handle">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <span class="fake-title">{{
        !$language ? "See Full Attendance" : "সম্পূর্ণ উপস্থিতি দেখুন"
      }}</span>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item class="clear-background-item">
        <attendance-component
          :batch-type="batchType"
          :batch-students-object="students"
          :allow-edit="allowEdit"
          @switch="handle"
        ></attendance-component>
      </v-tab-item>
      <v-tab-item class="clear-background-item">
        <detailed-attendance
          :batch-type="batchType"
          :batch-students-object="students"
          @switch="handle"
        ></detailed-attendance>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  <div v-else class="d-flex align-center justify-center" style="height: 200px">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import AttendanceComponent from "#ecf/batch/components/AttendanceComponent.vue";
import DetailedAttendance from "#ecf/batch/components/DetailedAttendance.vue";

export default {
  name: "AttendanceManager",
  inject: ["$courseId", "$batchId"],
  components: { AttendanceComponent, DetailedAttendance },
  props: ["batchType", "allowEdit"],
  data() {
    return {
      tab: 0,
      renderNow: {},
      students: {},
    };
  },
  async created() {
    await this.retrieveBatchStudentInfo();
  },
  methods: {
    handle() {
      this.tab = this.tab ^ 1;
      this.$emit("switch");
    },
    async retrieveBatchStudentInfo() {
      this.renderNow = false;
      try {
        this.students = await this.$store.dispatch(
          "batch/getStudentInformation",
          {
            partner_code: this.$route.params.partner_code,
            course_Id: this.$courseId(),
            batch_Id: this.$batchId(),
          }
        );
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fake-title {
  font-family: "Poppins", "Helvetica", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem;
}

.clear-background-item {
  background-color: #f3f7ff;
}
</style>
