<template>
  <div class="container">
    <v-btn
      v-if="hasLeft"
      depressed
      color="#a4a4a4"
      class="white--text rounded rounded-r-0 pa-0 d-flex align-center"
      :width="dimSlider.width"
      :height="dimSlider.height"
      min-width="20"
      min-height="20"
      @click="changeDate(-1)"
    >
      <v-icon :size="screenSize < 600 ? 18 : screenSize < 900 ? 28 : 32"
        >mdi-chevron-left</v-icon
      >
      <span v-show="!hasRight && screenSize >= 600" class="content-text"
        >Prev</span
      >
    </v-btn>
    <div
      :class="[
        { 'pa-2': screenSize >= 600 },
        'd-flex justify-center align-center block'
      ]"
    >
      <span :class="[screenSize >= 900 ? 'pr-3' : 'pr-1', 'content-text']">{{
        adjustFormat(dateList[currentIndex])
      }}</span>
      <v-menu offset-x>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="screenSize >= 900"
            class="roudned-xl elevation-3"
            :width="dimIconBtn"
            :height="dimIconBtn"
            min-width="20"
            min-height="20"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="black" :size="dimIcon">mdi-calendar-month</v-icon>
          </v-btn>
          <v-btn v-else icon v-bind="attrs" v-on="on">
            <v-icon color="black" :size="dimIcon">mdi-calendar-month</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="selected" no-title></v-date-picker>
      </v-menu>
    </div>
    <v-btn
      v-if="hasRight"
      depressed
      color="#a4a4a4"
      class="white--text rounded rounded-l-0 pa-0 d-flex align-center"
      :width="dimSlider.width"
      :height="dimSlider.height"
      min-width="20"
      min-height="20"
      @click="changeDate(1)"
    >
      <span v-show="!hasLeft && screenSize >= 600" class="content-text"
        >Next</span
      >
      <v-icon :size="screenSize < 600 ? 18 : screenSize < 900 ? 28 : 32"
        >mdi-chevron-right</v-icon
      >
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "MkDateSlider",
  props: ["dateList"],
  data() {
    return {
      currentIndex: 0,
      mostRight: 0,
      selected: ""
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    hasLeft() {
      return this.currentIndex > 0;
    },
    hasRight() {
      return this.currentIndex < this.mostRight;
    },
    dimSlider() {
      let s = this.screenSize;
      let width = 0,
        height = 0;
      if (this.hasLeft && this.hasRight) {
        width = s < 600 ? 20 : s < 900 ? 40 : s < 1600 ? 50 : 60;
      } else {
        width = s < 600 ? 30 : s < 900 ? 80 : s < 1600 ? 100 : 120;
      }
      height = s < 600 ? 30 : s < 900 ? 40 : s < 1600 ? 45 : 50;
      return { width: width, height: height };
    },
    dimIcon() {
      let s = this.screenSize;
      return s < 600 ? 16 : s < 900 ? 20 : s < 1600 ? 24 : 28;
    },
    dimIconBtn() {
      let s = this.screenSize;
      return s < 600 ? 20 : s < 900 ? 22 : s < 1600 ? 26 : 30;
    }
  },
  watch: {
    selected(newValue, oldValue) {
      let temp = new Date(newValue);
      let l = new Date(this.dateList[0]);
      let r = new Date(this.dateList[this.mostRight]);
      if (temp > r || temp < l) {
        this.throwError1();
      }
      let ni = this.dateList.indexOf(newValue);
      if (ni < 0) {
        this.throwError2(newValue);
        return;
      }
      this.jumpDate(ni);
    }
  },
  created() {
    this.mostRight = this.dateList.length - 1;
    this.currentIndex = this.mostRight;
    this.selected = this.dateList[this.currentIndex];
  },
  methods: {
    adjustFormat(date) {
      let z = new Date(date);
      let month = z.toLocaleString("default", {
        month: this.screenSize < 600 ? "short" : "long"
      });
      let year = z.toLocaleString("default", {
        year: this.screenSize < 600 ? "2-digit" : "numeric"
      });
      return `${z.getDate()} ${month} ${year}`;
    },
    changeDate(inc) {
      this.currentIndex += inc;
      this.$emit("slide", this.dateList[this.currentIndex]);
    },
    jumpDate(index) {
      this.currentIndex = index;
      this.$emit("slide", this.dateList[this.currentIndex]);
    },
    throwError1() {
      this.$root.$emit("alert", [
        undefined,
        `Must select a date between ${this.adjustFormat(
          this.dateList[0]
        )} and ${this.adjustFormat(this.dateList[this.mostRight])}`
      ]);
    },
    throwError2(date) {
      this.$root.$emit("alert", [undefined, `No class occured on ${date}`]);
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  border: 1px solid #a4a4a4;
  border-radius: 6px;
  display: flex;
  align-content: center;
  padding: 0px;
}

.block {
  width: 100%;
  transition: width 0.5s ease;
  @media screen and (max-width: 599px) {
    height: 30px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 40px;
  }
}

.content-text {
  @media screen and (max-width: 599px) {
    font-size: 11px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
}
</style>
