<template>
  <div v-if="renderNow" class="table">
    <div class="header px-3 elevation-3">
      <v-row no-gutters>
        <v-col cols="2">
          <v-row class="my-0">
            <v-col class="d-flex align-center justify-center"
              ><span class="content-text">{{
                !$language ? "Avatar" : "ছবি"
              }}</span></v-col
            >
            <v-col v-if="screenSize >= 900" cols="3"></v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Name" : "নাম"
          }}</span></v-col
        >
        <v-col class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Number" : "মোবাইল নম্বর"
          }}</span></v-col
        >
        <v-col v-if="screenSize >= 900" class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Email" : "ইমেইল"
          }}</span></v-col
        >
        <v-col :cols="screenSize < 900 ? 3 : 2">
          <v-row class="my-0">
            <v-col v-if="screenSize >= 900" cols="2"></v-col>
            <v-col class="d-flex align-center justify-center"
              ><span class="content-text">{{
                !$language ? "Add/Remove" : "সংযোগ/অপসারণ"
              }}</span></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div :class="['content px-4 pt-2', { 'content-edit': addMode }]">
      <no-content-component v-if="studentList.length == 0" :message="noContentMessage"></no-content-component>
      <v-row
        v-for="(student, index) in studentList"
        :key="index"
        no-gutters
        class="content-item"
      >
        <v-col cols="2">
          <v-row>
            <v-col
              :cols="screenSize < 900 ? 9 : 8"
              class="d-flex align-center justify-center"
            >
              <v-avatar
                :color="student.color"
                class="white--text"
                :size="avatarSize"
              >
                <span class="content-text">{{ student.avatar }}</span>
              </v-avatar>
            </v-col>
            <!-- <v-col cols="4"></v-col> -->
          </v-row>
        </v-col>
        <v-col class="d-flex align-center"
          ><span class="content-text text-truncate">{{
            student.name
          }}</span></v-col
        >
        <v-col class="d-flex align-center"
          ><span class="content-text text-truncate">{{
            student.number
          }}</span></v-col
        >
        <v-col v-if="screenSize >= 900" class="d-flex align-center"
          ><span class="content-text">{{ student.email }}</span></v-col
        >
        <v-col
          :cols="screenSize < 900 ? 3 : 2"
          class="d-flex align-center justify-center"
        >
          <v-row class="ma-0">
            <!-- <v-col v-if="screenSize >= 900" cols="2"></v-col> -->
            <v-col
              :cols="screenSize >= 900 ? '10' : '12'"
              class="d-flex align-center justify-center ml-4"
            >
              <mk-check-box
                :disabled="allowEdit"
                color="#fc6222"
                :index="index"
                :size-object="checkDim"
                min-width="12"
                :active="student.add"
                @click="mark"
              ></mk-check-box>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-if="addMode" class="controls">
      <transition>
        <v-row class="px-3">
          <v-col class="d-flex justify-end pr-5">
            <v-btn
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :x-small="screenSize < 600"
              outlined
              color="red accented-3"
              :class="[screenSize > 600 ? 'mr-5' : 'mr-2 ', 'rounded-lg']"
              @click="handleCancel"
            >
              <v-icon v-if="screenSize > 600">cancel</v-icon>
              <span class="custom-font">{{
                !$language ? "Cancel" : "বাতিল করুন"
              }}</span>
            </v-btn>
            <v-btn
              :loading="saving['status']"
              depressed
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :x-small="screenSize < 600"
              color="#0099dd"
              :class="[
                'white--text rounded-lg',
                screenSize > 600 ? 'mr-5 px-10' : '',
              ]"
              @click="applyChanges"
            >
              <span class="custom-font">{{
                !$language ? "Save Changes" : "সেভ করুন"
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </transition>
    </div>
    <v-app>
      <v-dialog v-model="showDeleteDialogue" :width="delDialogWidth">
        <v-sheet class="del-style">
          <v-row class="ma-0">
            <v-col cols="3" class="d-flex align-center justify-center">
              <v-avatar :size="avatarSize * 1.75" color="#ff4848"
                ><v-icon :size="avatarSize * 1.25" color="white"
                  >mdi-account-cancel-outline</v-icon
                ></v-avatar
              >
            </v-col>
            <v-col cols="9" class="d-flex flex-column justify-start">
              <p class="rm-heading">
                {{ !$language ? "Remove Students" : "শিক্ষার্থী অপসারণ করুন" }}
              </p>
              <p v-if="!$language" class="rm-text">
                Do you want to remove {{ deleteWord }}?
              </p>
              <p v-else class="rm-text">
                আপনি কি {{ deleteWordBN }} অপসারণ করতে চান?
              </p>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="3"></v-col>
            <v-col cols="9">
              <v-list dense>
                <v-list-item
                  v-for="(student, index) in formattedDeleteList"
                  :key="index"
                >
                  <p class="rm-item">{{ student.name }}</p>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-3">
            <v-col cols="2"></v-col>
            <v-col cols="8" class="d-flex justify-space-around align-center">
              <v-btn
                color="#0099dc"
                class="elevation-0"
                outlined
                :x-large="screenSize >= 1600"
                :large="screenSize >= 900 && screenSize < 1600"
                :x-small="screenSize < 600"
                @click="showDeleteDialogue = false"
                ><span>{{ !$language ? "Cancel" : "বাতিল করুন" }}</span></v-btn
              >
              <v-btn
                color="#ff4848"
                class="white--text elevation-0"
                :x-large="screenSize >= 1600"
                :large="screenSize >= 900 && screenSize < 1600"
                :x-small="screenSize < 600"
                @click="save"
                ><span>{{ !$language ? "Delete" : "ডিলিট করুন" }}</span></v-btn
              >
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </v-sheet>
      </v-dialog>
    </v-app>
  </div>
  <div v-else class="d-flex align-center justify-center" style="height: 200px">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import MkCheckBox from "./MkCheckBox.vue";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "StudentManagementComponent",
  components: { MkCheckBox, NoContentComponent },
  inject: ["$courseId", "$batchId"],
  props: ["allowEdit"],
  data() {
    return {
      addMode: false,
      addList: {},
      deleteList: {},
      studentList: [],
      batchStudentsObject: {},
      organizationStudentsObject: {},
      chars: "abcdefghijklmnopqrstuvwxyz",
      selected: 0,
      base: 0,
      renderNow: false,
      colors: ["pink", "purple", "indigo", "teal", "amber"],
      saving: {},
      showDeleteDialogue: false,
      changeP: 0,
      changeN: 0,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    selectAllIcon() {
      if (this.selected > 0) {
        return "mdi-minus";
      }
      return "mdi-check";
    },
    checkDim() {
      let dim =
        this.screenSize < 600
          ? 12
          : this.screenSize < 900
          ? 14
          : this.screenSize < 1600
          ? 18
          : 24;
      return { height: dim, width: dim };
    },
    avatarSize() {
      return this.screenSize < 600
        ? 21
        : this.screenSize < 900
        ? 24
        : this.screenSize < 1600
        ? 36
        : 45;
    },
    deleteWord() {
      return Object.keys(this.deleteList).length > 1
        ? "these students"
        : "this student";
    },
    deleteWordBN() {
      return Object.keys(this.deleteList).length > 1
        ? "এই শিক্ষার্থীদের"
        : "এই শিক্ষার্থীকে";
    },
    delDialogWidth() {
      let s = this.screenSize;
      return s < 600
        ? Math.ceil(0.75 * s)
        : s < 900
        ? Math.ceil(0.5 * s)
        : Math.ceil(0.3 * s);
    },
    formattedDeleteList() {
      return this.studentList.filter((e) => {
        if (e.id in this.deleteList) return true;
        else return false;
      });
    },
    noContentMessage() {
      return this.$language
        ? "বর্তমানে এই ব্যাচ কোনো শিক্ষার্থী নেই"
        : "Currently there are no students in this batch";
    },
  },
  async created() {
    this.renderNow = false;
    await this.retrieveBatchStudentInfo();
    await this.retrieveOrganizationStudentInfo();
    this.populateStudentList();
    this.renderNow = true;
  },
  methods: {
    randomColour() {
      let i = Math.round(Math.random() * 100) % 5;
      return this.colors[i];
    },
    mark(index) {
      if (this.studentList[index].add) {
        this.$set(this.deleteList, this.studentList[index].id, true);
        this.$delete(this.addList, this.studentList[index].id);
      } else {
        this.$set(this.addList, this.studentList[index].id, true);
        this.$delete(this.deleteList, this.studentList[index].id);
      }
      this.studentList[index].add = !this.studentList[index].add;
      this.addMode = true;
    },
    async retrieveBatchStudentInfo() {
      try {
        this.batchStudentsObject = await this.$store.dispatch(
          "batch/getStudentInformation",
          {
            partner_code: this.$route.params.partner_code,
            course_Id: this.$courseId(),
            batch_Id: this.$batchId(),
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async retrieveOrganizationStudentInfo() {
      try {
        this.organizationStudentsObject = await this.$store.dispatch(
          "batch/getOrganizationStudentInformation",
          {
            partner_code: this.$route.params.partner_code,
            course_Id: this.$courseId(),
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    avatarInitials(nameStr) {
      let chars = nameStr.split(" ");
      let ret = "";
      for (let c of chars) {
        ret += c[0];
      }
      return ret;
    },
    populateStudentList() {
      if (this.studentList.length) this.studentList = [];
      let s = this.organizationStudentsObject;
      for (let student of Object.keys(s)) {
        let inThisBatch = false;
        if (student in this.batchStudentsObject) {
          inThisBatch = true;
          this.selected++;
          this.$set(this.addList, student, true);
        }
        this.studentList.push({
          id: student,
          name: s[student].name,
          avatar: this.avatarInitials(s[student].name),
          color: this.randomColour(),
          email: s[student].email,
          number: s[student].phone,
          inThisBatch: inThisBatch,
          add: inThisBatch,
        });
      }
    },

    handleCancel() {
      this.addMode = false;
      for (let student of this.studentList) {
        student.add = student.inThisBatch;
      }
    },

    async applyChanges() {
      if (Object.keys(this.deleteList).length > 0) {
        this.showDeleteDialogue = true;
      } else {
        await this.save();
      }
    },

    async save() {
      if(this.saving.status) return;
      this.$set(this.saving, "status", false);
      try {
        this.$set(this.saving, "status", true);
        await this.$store.dispatch("batch/updateStudentList", {
          partner_code: this.$route.params.partner_code,
          course_id: this.$courseId(),
          batch_id: this.$batchId(),
          data: Object.keys(this.addList),
        });
        let P = 0;
        let N = 0;
        for (let student of this.studentList) {
          if (student.inThisBatch && !student.add) N++;
          if (!student.inThisBatch && student.add) P++;
        }
        let mp = P > 0 ? `added ${P} student(s)` : "";
        let mn = N > 0 ? `removed ${N} student(s)` : "";
        let cnt = P > 0 && N > 0 ? ", " : "";
        let msg_out = mp + cnt + mn;
        this.$root.$emit("alert", ["Success", msg_out]);
      } catch (e) {
        this.handleCancel();
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.saving, "status", false);
        for (let student of this.studentList) {
          student.inThisBatch = student.add;
        }
        this.deleteList = {};
        this.addMode = false;
        this.showDeleteDialogue = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  background-color: white;

  @media screen and (min-width: 1601px) {
    width: 1400px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    width: 975px;
  }

  @media screen and (max-width: 900px) {
    width: calc(100vw - 65px);
  }
}
.header {
  /* border: 1px solid #8b8b8b7f; */
  border: 0px solid #00000000;
  border-radius: 10px 10px 0px 0px;
  z-index: 1;

  @media screen and (min-width: 1601px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 599px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.content {
  max-height: 60vh;
  border: 2px solid #00000000;
  border-top-width: 0px;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-edit {
  border-radius: 0px 0px 0px 0px;
  border-bottom-width: 0px;
}

.controls {
  border: 2px solid #00000000;
  border-top-width: 0px;
  border-radius: 0px 0px 10px 10px;
  padding: 30px 10px 20px 10px;
}

.content-item {
  border-style: solid;
  border-color: #8b8b8b7f;
  border-width: 0px 0px 1px 0px;

  @media screen and (min-width: 1601px) {
    padding: 0px 8px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 0px 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 0px 5px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 3px;
  }
}

.content-text {
  display: inline-block;
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
    max-width: 125px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
    max-width: 60px;
  }
}

.del-style {
  @media screen and (min-width: 1601px) {
    padding: 30px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 20px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 15px;
    padding-bottom: 0px;
  }

  @media screen and (max-width: 599px) {
    padding: 10px;
    padding-bottom: 0px;
  }
}

.rm-heading {
  font-weight: 600;
  @media screen and (min-width: 1601px) {
    font-size: 22px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.rm-text {
  font-weight: 200;
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.rm-item {
  font-weight: 400;
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
