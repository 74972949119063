var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "div",
            { staticClass: "header px-3 elevation-3" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [
                              _c("span", { staticClass: "content-text" }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Avatar" : "ছবি")
                                ),
                              ]),
                            ]
                          ),
                          _vm.screenSize >= 900
                            ? _c("v-col", { attrs: { cols: "3" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "d-flex align-center" }, [
                    _c("span", { staticClass: "content-text" }, [
                      _vm._v(_vm._s(!_vm.$language ? "Name" : "নাম")),
                    ]),
                  ]),
                  _c("v-col", { staticClass: "d-flex align-center" }, [
                    _c("span", { staticClass: "content-text" }, [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Number" : "মোবাইল নম্বর")
                      ),
                    ]),
                  ]),
                  _vm.screenSize >= 900
                    ? _c("v-col", { staticClass: "d-flex align-center" }, [
                        _c("span", { staticClass: "content-text" }, [
                          _vm._v(_vm._s(!_vm.$language ? "Email" : "ইমেইল")),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenSize < 900 ? 3 : 2 } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0" },
                        [
                          _vm.screenSize >= 900
                            ? _c("v-col", { attrs: { cols: "2" } })
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [
                              _c("span", { staticClass: "content-text" }, [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Add/Remove"
                                      : "সংযোগ/অপসারণ"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { class: ["content px-4 pt-2", { "content-edit": _vm.addMode }] },
            [
              _vm.studentList.length == 0
                ? _c("no-content-component", {
                    attrs: { message: _vm.noContentMessage },
                  })
                : _vm._e(),
              _vm._l(_vm.studentList, function (student, index) {
                return _c(
                  "v-row",
                  {
                    key: index,
                    staticClass: "content-item",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: { cols: _vm.screenSize < 900 ? 9 : 8 },
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: student.color,
                                      size: _vm.avatarSize,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "content-text" },
                                      [_vm._v(_vm._s(student.avatar))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c(
                        "span",
                        { staticClass: "content-text text-truncate" },
                        [_vm._v(_vm._s(student.name))]
                      ),
                    ]),
                    _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c(
                        "span",
                        { staticClass: "content-text text-truncate" },
                        [_vm._v(_vm._s(student.number))]
                      ),
                    ]),
                    _vm.screenSize >= 900
                      ? _c("v-col", { staticClass: "d-flex align-center" }, [
                          _c("span", { staticClass: "content-text" }, [
                            _vm._v(_vm._s(student.email)),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex align-center justify-center",
                        attrs: { cols: _vm.screenSize < 900 ? 3 : 2 },
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center ml-4",
                                attrs: {
                                  cols: _vm.screenSize >= 900 ? "10" : "12",
                                },
                              },
                              [
                                _c("mk-check-box", {
                                  attrs: {
                                    disabled: _vm.allowEdit,
                                    color: "#fc6222",
                                    index: index,
                                    "size-object": _vm.checkDim,
                                    "min-width": "12",
                                    active: student.add,
                                  },
                                  on: { click: _vm.mark },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm.addMode
            ? _c(
                "div",
                { staticClass: "controls" },
                [
                  _c(
                    "transition",
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end pr-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    _vm.screenSize > 600 ? "mr-5" : "mr-2 ",
                                    "rounded-lg",
                                  ],
                                  attrs: {
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    "x-small": _vm.screenSize < 600,
                                    outlined: "",
                                    color: "red accented-3",
                                  },
                                  on: { click: _vm.handleCancel },
                                },
                                [
                                  _vm.screenSize > 600
                                    ? _c("v-icon", [_vm._v("cancel")])
                                    : _vm._e(),
                                  _c("span", { staticClass: "custom-font" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Cancel" : "বাতিল করুন"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "white--text rounded-lg",
                                    _vm.screenSize > 600 ? "mr-5 px-10" : "",
                                  ],
                                  attrs: {
                                    loading: _vm.saving["status"],
                                    depressed: "",
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    "x-small": _vm.screenSize < 600,
                                    color: "#0099dd",
                                  },
                                  on: { click: _vm.applyChanges },
                                },
                                [
                                  _c("span", { staticClass: "custom-font" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language
                                          ? "Save Changes"
                                          : "সেভ করুন"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-app",
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: _vm.delDialogWidth },
                  model: {
                    value: _vm.showDeleteDialogue,
                    callback: function ($$v) {
                      _vm.showDeleteDialogue = $$v
                    },
                    expression: "showDeleteDialogue",
                  },
                },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "del-style" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-center",
                              attrs: { cols: "3" },
                            },
                            [
                              _c(
                                "v-avatar",
                                {
                                  attrs: {
                                    size: _vm.avatarSize * 1.75,
                                    color: "#ff4848",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: _vm.avatarSize * 1.25,
                                        color: "white",
                                      },
                                    },
                                    [_vm._v("mdi-account-cancel-outline")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex flex-column justify-start",
                              attrs: { cols: "9" },
                            },
                            [
                              _c("p", { staticClass: "rm-heading" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language
                                        ? "Remove Students"
                                        : "শিক্ষার্থী অপসারণ করুন"
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.$language
                                ? _c("p", { staticClass: "rm-text" }, [
                                    _vm._v(
                                      " Do you want to remove " +
                                        _vm._s(_vm.deleteWord) +
                                        "? "
                                    ),
                                  ])
                                : _c("p", { staticClass: "rm-text" }, [
                                    _vm._v(
                                      " আপনি কি " +
                                        _vm._s(_vm.deleteWordBN) +
                                        " অপসারণ করতে চান? "
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c("v-col", { attrs: { cols: "3" } }),
                          _c(
                            "v-col",
                            { attrs: { cols: "9" } },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                _vm._l(
                                  _vm.formattedDeleteList,
                                  function (student, index) {
                                    return _c("v-list-item", { key: index }, [
                                      _c("p", { staticClass: "rm-item" }, [
                                        _vm._v(_vm._s(student.name)),
                                      ]),
                                    ])
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "py-3" },
                        [
                          _c("v-col", { attrs: { cols: "2" } }),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-space-around align-center",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "elevation-0",
                                  attrs: {
                                    color: "#0099dc",
                                    outlined: "",
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    "x-small": _vm.screenSize < 600,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteDialogue = false
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Cancel" : "বাতিল করুন"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text elevation-0",
                                  attrs: {
                                    color: "#ff4848",
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    "x-small": _vm.screenSize < 600,
                                  },
                                  on: { click: _vm.save },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Delete" : "ডিলিট করুন"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "2" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "d-flex align-center justify-center",
          staticStyle: { height: "200px" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }